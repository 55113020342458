<template>
    <div class="filter">
        <div class="filter-name">
            {{ name }}
        </div>

        <el-tree
            ref="tree"
            :data="useData"
            show-checkbox
            node-key="id"
            :default-checked-keys="defaultCheckedKeys"
            :default-expanded-keys="defaultExpandedKeys"
            :props="valueProps"
            @check="valueChange"
            :render-after-expand="false"
            class="scrollbar"
        >
            <span slot-scope="{ node }" class="custom-tree-node">
                <el-tooltip
                    v-if="node.label.length >= 25"
                    class="item"
                    effect="dark"
                    :content="node.label"
                    placement="right"
                >
                    <span>{{ node.label }}</span>
                </el-tooltip>
                <span v-else class="custom-tree-node">{{ node.label }}</span>
            </span>
        </el-tree>
    </div>
</template>

<script>
export default {
    name: 'ListFilter',
    props: {
        defaultValue: {
            type: [String, Number],
            default: ''
        },
        value: { type: String, default: '' },
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Array,
            default: () => {
                return [];
            }
        },
        defaultProps: {
            type: Object,
            default: () => {
                return {};
            }
        },
        allNodeName: {
            type: String,
            default: '全部'
        },
        allNodeKey: {
            type: [String, Number],
            default: 'all'
        },
        allBackNull: {
            type: Boolean,
            default: false
        },
        createBack: {
            type: Boolean,
            default: true
        },
        rootNode: {
            type: Boolean,
            default: true
        },
        noBest: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            defaultExpandedKeys: []
        };
    },
    computed: {
        valueProps() {
            return {
                children: 'children',
                label: data => {
                    return (this.$i18n.locale.indexOf('zh') !== -1 ? data.chName : data.enName) || data.label;
                }
            };
        },
        useData() {
            return this.rootNode
                ? [
                      {
                          chName: this.allNodeName,
                          enName: this.allNodeName,
                          label: this.allNodeName,
                          id: this.allNodeKey,
                          children: [...this.data]
                      }
                  ]
                : this.data;
        },
        defaultCheckedKeys() {
            let list = [];
            if (this.defaultValue instanceof Array) {
                list = this.defaultValue;
            } else {
                list = this.defaultValue.split(',');
            }
            return this.defaultValue
                ? list.map(item => {
                      return Number(item);
                  })
                : [];
        }
    },
    watch: {
        data() {
            this.$nextTick(() => {
                if (this.createBack && this.data.length > 0) {
                    this.$nextTick(() => {
                        this.backValue(this.$refs.tree.getCheckedKeys());
                    });
                }
            });
        },
        value() {
            if (!this.value) {
                this.$nextTick(() => {
                    this.$refs.tree.setChecked('all', false, true);
                });
            }
        }
    },
    methods: {
        valueChange(data, node) {
            console.log(node);
            let backList = [];
            if (!node.checkedKeys.includes('all' && this.noBest)) {
                backList = node.checkedKeys;
            } else {
                node.halfCheckedNodes.forEach(item => {
                    item.children.forEach(child => {
                        if (node.checkedKeys.includes(child.id)) {
                            backList.push(child.id);
                        }
                    });
                });
            }

            this.backValue(backList);
        },
        backValue(list = []) {
            console.log(list);
            if (list.indexOf('all') !== -1 && this.allBackNull) {
                this.$emit('input', this.getListStr(''));
            } else {
                this.$emit('input', this.getListStr(list));
            }
        },
        getListStr(list = [], tag = ',') {
            return list
                ? list
                      .filter(item => {
                          return item !== 'all';
                      })
                      .join(',')
                : '';
        },
        update() {
            this.$refs.tree.setCheckedKeys(this.defaultCheckedKeys);
            console.log(this.$refs.tree.getCheckedKeys());
            this.backValue(this.$refs.tree.getCheckedKeys());
        }
    }
};
</script>

<style lang="scss" scoped>
.filter {
    margin-top: 30px;
}
.filter-name {
    font-size: 14px;
    font-weight: bold;
    color: rgba(41, 44, 51, 1);
    line-height: 20px;
    margin-bottom: 12px;
}

.el-tree {
    padding: 5px 19px 5px 15px;
    max-height: 300px;
    overflow: auto;
}

.custom-tree-node {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    padding-right: 10px;
    box-sizing: border-box;
}
</style>

<style lang="scss">
@import '../styles/variables';

.filter {
    .el-tree-node__content {
        height: 40px;
        position: relative;
    }

    .el-tree-node__expand-icon {
        position: absolute;
        right: 0;
        &::before {
            content: '\e6e0';
        }
    }

    .el-tree-node__label {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #292c33;
    }

    .is-checked {
        .el-tree-node__label {
            color: $--color-warning;
        }
    }

    .el-tree > .el-tree-node.is-checked > .el-tree-node__content {
        .el-tree-node__label {
            color: #292c33;
        }
    }

    .el-tree > .el-tree-node > .el-tree-node__content {
        .el-checkbox__input {
            display: none;
        }
        .is-indeterminate,
        .is-checked,
        .is-focus {
            display: block;
        }
    }
}
</style>
