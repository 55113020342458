<template>
    <router-link :to="'/brandDetail?id=' + info.id">
        <el-card :body-style="{ padding: '0px' }">
            <div class="row">
                <div class="top">
                    <div
                        class="img bg"
                        :style="{
                            backgroundImage: `url(${logo})`
                        }"
                    ></div>
                </div>
                <div class="info">
                    <div class="name">
                        <span class="text" v-html="getImport(name)"> </span>

                        <span class="iconfont" :class="{ active: isCollection }">{{
                            isCollection ? '&#xe8c3;' : '&#xe616;'
                        }}</span>

                        <span class="iconfont">&#xe617;</span>
                    </div>

                    <div class="info-item">
                        <div class="info-name">
                            {{ $t('chan-pin-shu-liang') }}
                        </div>
                        <div class="info-val">{{ info.quantity }}</div>
                    </div>

                    <div class="info-item">
                        <div class="info-name">
                            {{ $t('suo-zai-di-qu') }}
                        </div>
                        <div class="info-val">{{ country }}</div>
                    </div>

                    <div class="info-item">
                        <div class="info-name">
                            {{ $t('chan-pin-lei-bie') }}
                        </div>
                        <div class="info-val">
                            <el-tag
                                size="mini"
                                effect="dark"
                                v-for="(item, index) in categoriesList"
                                :key="index"
                                v-html="getImport(item)"
                            ></el-tag>
                        </div>
                    </div>

                    <div class="info-item">
                        <div class="info-name">
                            {{ $t('ying-yong-ling-yu') }}
                        </div>
                        <div class="info-val">165</div>
                    </div>
                </div>
                <div class="right">
                    <el-row>
                        <el-button
                            type="text"
                            :class="{ active: isCollection }"
                            @click.stop.prevent="collection($event)"
                        >
                            <div class="btn-content">
                                <span class="iconfont">{{ isCollection ? '&#xe8c3;' : '&#xe616;' }}</span>
                                <span>{{ $t('guan-zhu') }}</span>
                            </div>
                        </el-button>
                        <el-popover placement="bottom" trigger="hover">
                            <el-button type="text" slot="reference" @click.stop.prevent="">
                                <div class="btn-content">
                                    <span class="iconfont">&#xe617;</span>
                                    <span>{{ $t('fen-xiang') }}</span>
                                </div>
                            </el-button>
                            <share isBrand :info="info" v-show="isChinese"></share>
                            <share isBrand :info="info" local="en" v-show="!isChinese"></share>
                        </el-popover>
                    </el-row>

                    <el-button type="warning" plain @click.stop.prevent="advisory(info, 'vendor')">
                        {{ $t('zi-xun-1') }}</el-button
                    >
                </div>
            </div>
        </el-card>
    </router-link>
</template>
<script>
import UserStoreOperation from '../../mixins/UserStoreOperation';
import brandInfo from '../../mixins/brandInfo';
import Share from '../Share.vue';
export default {
    name: 'Row',
    mixins: [UserStoreOperation, brandInfo],
    data() {
        return {
            collectionType: 'VENDOR'
        };
    },
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        search: {
            type: String,
            default: ''
        }
    },
    computed: {
        categoriesList() {
            if (this.categories) {
                return this.categories.split('/');
            } else {
                return [];
            }
        }
    },
    watch: {
        info() {
            if (this.info.id) {
                this.cId = this.info.id;
            }
        }
    },
    mounted() {
        if (this.info.id) {
            this.cId = this.info.id;
        }
    },
    components: {
        Share
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';
$colors: [#6060b3, #607cb3, #6097b3, #60b397, #a2b360, #B39A60];

.row {
    display: flex;
}
.el-card {
    transition: all ease-in-out 0.3s;
    box-shadow: none;
    position: relative;
    box-sizing: border-box;
    transform: translate3d(0, 0, 0);

    &:hover {
        box-shadow: 0px 8px 17px 0px rgba(255, 165, 38, 0.12);
        border: 1px solid rgba(255, 165, 38, 1);
        transform: scaleX(1.02);
        .el-row {
            display: flex;
        }

        .name {
            .iconfont {
                display: none;
            }
        }
    }
}
.new-img {
    position: absolute;
    top: 0;
    left: 10px;
    width: 30px;
    height: 30px;
}
.top {
    // padding: 26px;
    .img {
        width: 240px;
        height: 240px;
        // background-size: contain;
        background-repeat: no-repeat;
    }
}

.name {
    font-size: 20px;
    color: $--color-text-regular;
    line-height: 28px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .text {
        margin-right: 20px;
    }

    .iconfont {
        display: block;
    }
}

.el-card {
    cursor: pointer;
}

.info {
    padding: 20px;
    position: relative;
    flex-grow: 1;
    overflow: hidden;
}

.sub {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: rgba(86, 91, 102, 1);
    line-height: 24px;
    margin-top: 7px;
}
.iconfont {
    color: #dcdfe6;
    font-size: 28px;
    display: block;
    margin-right: 10px;
    font-weight: normal;
    &.active {
        color: $--color-warning;
    }
}

.right {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 30px;
    justify-content: center;
    position: relative;
    flex-shrink: 0;
    .el-row {
        position: absolute;
        top: 30px;
        right: 10px;
        display: flex;
        align-items: center;
        display: none;
    }
}

.el-button--warning.is-plain {
    background-color: transparent;
    padding: 10px 15px;
    min-width: 135px;

    &:hover {
        background-color: $--color-warning;
    }
}

.info-item {
    display: flex;
    align-items: center;
    .info-name {
        font-size: 13px;
        color: rgba(188, 193, 204, 1);
        line-height: 33px;
        margin-right: 20px;
        flex-shrink: 0;
    }

    .info-val {
        font-size: 12px;
        color: rgba(86, 91, 102, 1);
        line-height: 22px;
        flex-wrap: wrap;
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 20px;
    }
}

.btn-content {
    display: flex;
    align-items: center;
    font-size: 13px;

    color: #bcc1cc;
    .iconfont {
        margin-right: 0px;
    }
}
.el-button--text {
    padding: 14px 20px;
    &:hover {
        .btn-content {
            color: $--color-warning;

            .iconfont {
                color: $--color-warning;
            }
        }
    }

    &.active {
        .btn-content {
            color: $--color-warning;

            .iconfont {
                color: $--color-warning;
            }
        }
    }
}

.el-tag {
    margin-right: 10px;
    border-width: 0px;
    border-radius: 0px;
    @each $color in $colors {
        $index: index($colors, $color);
        &:nth-child(6n + #{$index}) {
            background-color: $color;
        }
    }
}
</style>
