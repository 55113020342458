<template>
    <div class="brand">
        <swiper id="banner" ref="banner" class="swiper banner" :options="bannerOPtions">
            <swiper-slide v-for="item in banners" :key="item.id" @click="clickSlide()">
                <auto-img :src="item.img" mul="29%" :path="item.url"></auto-img>
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-white" slot="pagination" v-siwperPagination></div>
        </swiper>
        <el-container class="brand-main">
            <el-aside width="240px">
                <div class="filter-title">
                    <span>{{ $t('shai-xuan-tiao-jian') }}</span>
                    <el-button type="text" @click="refreash">{{ $t('zhong-zhi-shai-xuan-tiao-jian') }}</el-button>
                </div>

                <CountryTree v-model="chooseCountry" :continent.sync="continent"></CountryTree>

                <ProductCategoryTree v-model="chooseCategorys" :defaultValue="defaultCategory"></ProductCategoryTree>

                <list-filter
                    :name="$t('qi-ye-lei-xing')"
                    :data="enterpriseTypes"
                    :allNodeName="$t('quan-bu-lei-xing')"
                    v-model="chooseEnterpriseType"
                    :defaultValue="defaultEnterpriseType"
                    :defaultProps="defaultProps"
                    allBackNull
                ></list-filter>

                <ApplicationFieldTree
                    v-model="chooseApplicationFields"
                    :defaultValue="defaultApplicationField"
                ></ApplicationFieldTree>
            </el-aside>
            <el-container class="filter-show">
                <el-header height="56px" class="listTop">
                    <radio-group :list="sortList" v-model="sort"></radio-group>

                    <el-input
                        size="mini"
                        :placeholder="$t('zai-jie-guo-zhong-sou-suo')"
                        class="search"
                        v-model="searchKey"
                        clearable
                        @change="submitSearch"
                    >
                        <div slot="append" @click="submitSearch" class="iconfont">&#xe603;</div>
                    </el-input>

                    <span class="result" v-if="isChinese"
                        >{{ $t('dang-qian-jie-guo-gong') }}{{ totalElements }}{{ $t('ge-qi-ye') }}</span
                    >

                    <span class="result" v-else> Current: {{ totalElements }} result(s) </span>

                    <el-radio-group v-model="showType" class="show">
                        <el-radio-button :label="item.value" v-for="item in listTypes" :key="item.value">
                            <div class="btn-info">
                                <span class="iconfont" v-html="item.icon"></span>
                                <span>{{ $i18n.t(item.label) }}</span>
                            </div>
                        </el-radio-button>
                    </el-radio-group>
                </el-header>

                <el-main v-loading="loading">
                    <el-row type="flex" :gutter="30" v-if="showType == 'gride'">
                        <el-col v-for="item in list" :key="item.id">
                            <brand-gride :info="item" :search="search"></brand-gride>
                        </el-col>
                    </el-row>
                    <template v-else>
                        <div class="row" v-for="item in list" :key="item.id">
                            <brand-row :info="item" :search="search" />
                        </div>
                    </template>

                    <empty-page v-if="empty"></empty-page>
                </el-main>
                <el-footer>
                    <el-pagination
                        @size-change="onSizeChange"
                        @current-change="onCurrentChange"
                        :current-page="page"
                        :page-sizes="[10, 20, 30, 40, 50]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalElements"
                    >
                    </el-pagination>
                </el-footer>
            </el-container>
        </el-container>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import BrandGride from '../components/brand/Gride';
import BrandRow from '../components/brand/Row';
import { getBanner } from '../utils/CommonApi';
import pageableList from '../mixins/pageableList';
import ListFilter from '../components/ListFilter';
import { EnterpriseType, BrandSortType } from '../utils/AppState';
import CountryTree from '../components/tree/Countrys.vue';
import ProductCategoryTree from '../components/tree/ProductCategory';
import ApplicationFieldTree from '../components/tree/ApplicationField';
export default {
    name: 'Brand',
    mixins: [pageableList],
    data() {
        return {
            banners: [],
            bannerOPtions: {
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                speed: 600,
                parallax: true,
                // loop: true,
                preventClicks: false,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    bulletClass: 'banner-bullet',
                    bulletActiveClass: 'banner-bullet-active'
                }
            },
            sortList: [...BrandSortType].map(item => {
                return {
                    key: item[0],
                    name: item[1]
                };
            }),
            productCategorys: [],
            applicationFields: [],
            chooseCategorys: '',
            EnterpriseType,
            chooseEnterpriseType: '',
            chooseApplicationFields: '',
            start: true,
            url: '/vendorInfo/show1'
        };
    },
    computed: {
        ...mapState(['userInfo', 'keepAlive']),
        defaultCategory() {
            return this.$route.query.categoryIds || '';
        },
        enterpriseTypes() {
            return [...this.EnterpriseType].map(item => {
                return {
                    id: item[0],
                    label: this.$i18n.t(item[1])
                };
            });
        },
        defaultEnterpriseType() {
            return this.$route.query.enterpriseType || '';
        },
        defaultApplicationField() {
            return this.$route.query.applicationField || '';
        },
        listQuery() {
            return {
                categoryIds: this.chooseCategorys,
                enterpriseType: this.chooseEnterpriseType,
                applicationField: this.chooseApplicationFields,
                vendorSort: this.sort,
                countries: this.chooseCountry,
                continent: this.continent
            };
        }
    },
    components: {
        Swiper,
        SwiperSlide,
        BrandGride,
        BrandRow,
        ListFilter,
        CountryTree,
        ProductCategoryTree,
        ApplicationFieldTree
    },
    watch: {
        listQuery() {
            if (!this.start) {
                this.page = 1;
                this.getData();
            }
        },
        isChinese() {
            getBanner('VENDOR', !this.isChinese).then(res => {
                this.banners = res;
                this.$nextTick(() => {
                    this.$refs.banner.$swiper.update();
                });
            });
        }
    },
    mounted() {
        this.loading = true;
        getBanner('VENDOR', !this.isChinese).then(res => {
            this.banners = res;
            this.$nextTick(() => {
                this.$refs.banner.$swiper.update();
            });
        });
        this.$nextTick(() => {
            this.start = false;
            this.getData();
        });
    },
    beforeRouteLeave(to, from, next) {
        if (to.name === 'brandDetail') {
            this.$store.commit('updateKeepAlive', ['Brand']);
        } else {
            this.$store.commit('updateKeepAlive', []);
        }
        setTimeout(() => {
            next();
        }, 100);
    },
    methods: {
        clickSlide() {
            console.log('assssgg');
        },
        getFiliterInfo() {
            this.$http.get('/productCategory/allList').then(res => {
                this.productCategorys = res;
                this.chooseClassify = this.defaultCategory;
            });
            this.$http.get('/productTag/allList').then(res => {
                this.applicationFields = res
                    .filter(item => {
                        return item.id === 42;
                    })
                    .map(item => {
                        return item.children;
                    })
                    .flat();
                this.$nextTick(() => {
                    this.start = false;
                    this.getData();
                });
            });
        },
        refreash() {
            this.chooseCountry = '';
            this.chooseCategorys = '';
            this.chooseEnterpriseType = '';
            this.chooseApplicationFields = '';
            this.continent = '';
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../styles/pagebleTable';
</style>
