<template>
    <list-filter
        :name="$t('ying-yong-ling-yu')"
        :data="list"
        :allNodeName="$t('quan-bu-ling-yu')"
        v-model="chooseApplicationFields"
        :defaultValue="defaultValue"
        allBackNull
        @input="changeValue"
    ></list-filter>
</template>
<script>
import { delChild } from '../../utils/Array';
import ListFilter from '../ListFilter';
export default {
    name: 'ApplicationFieldTree',
    props: ['value', 'defaultValue'],
    data() {
        return {
            chooseApplicationFields: '',
            list: []
        };
    },
    watch: {
        value() {
            if (this.chooseApplicationFields !== this.value) {
                this.chooseApplicationFields = JSON.parse(JSON.stringify(this.value));
            }
        }
    },
    mounted() {
        this.$http
            .get('/productTag/application')
            .then(res => {
                this.list = delChild(res);
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });

        if (this.value) {
            this.chooseApplicationFields = this.value;
        }
    },
    computed: {},
    components: {
        ListFilter
    },
    methods: {
        changeValue(e) {
            this.$emit('input', e);
        }
    }
};
</script>
<style lang="less" scoped></style>
