<template>
    <list-filter
        :name="$t('chan-pin-lei-bie')"
        :data="showCategories"
        :allNodeName="$t('quan-bu-lei-bie')"
        v-model="chooseCategorys"
        :defaultValue="defaultValue"
        allBackNull
        @input="changeValue"
    ></list-filter>
</template>
<script>
import ListFilter from '../ListFilter';
export default {
    name: 'ProductCategoryTree',
    props: ['value', 'defaultValue', 'chooseNode'],
    data() {
        return {
            categories: [],
            chooseCategorys: ''
        };
    },
    watch: {
        value() {
            if (this.chooseCategorys !== this.value) {
                this.chooseCategorys = JSON.parse(JSON.stringify(this.value));
            }
        }
    },
    computed: {
        showCategories() {
            if (this.chooseNode) {
                return (
                    [...this.categories].find(item => {
                        return item.id === Number(this.chooseNode);
                    })?.children || []
                );
            } else {
                return this.categories;
            }
        }
    },
    mounted() {
        this.$http
            .get('/productCategory/tree')
            .then(res => {
                this.categories = this.getTreeData(res);
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });
    },
    methods: {
        getTreeData(data) {
            // 循环遍历json数据
            for (let i = 0; i < data.length; i++) {
                if (data[i].children.length < 1) {
                    // children若为空数组，则将children设为undefined
                    data[i].children = undefined;
                } else {
                    // children若不为空数组，则继续 递归调用 本方法
                    this.getTreeData(data[i].children);
                }
            }
            return data;
        },
        changeValue(e) {
            this.$emit('input', e);
        }
    },
    components: {
        ListFilter
    }
};
</script>
<style lang="less" scoped></style>
