<template>
    <div class="country">
        <list-filter
            :name="$t('guo-jia-di-qu')"
            :data="countrys"
            :allNodeName="$t('quan-bu-di-qu')"
            v-model="chooseCountry"
            allBackNull
            @input="changeCountry"
            noBest
        ></list-filter>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import ListFilter from '../ListFilter.vue';
export default {
    name: 'countryTree',
    props: ['value', 'continent'],
    data() {
        return {
            chooseCountry: ''
        };
    },
    computed: {
        ...mapState(['userInfo', 'areaList']),
        countrys() {
            return [...this.areaList].map(item => {
                return {
                    ...item,
                    id: item.value,
                    enName: this.$t(item.value),
                    children: item.children.map(child => {
                        return { ...child, id: child.value, children: null, enName: this.$t(child.value) };
                    })
                };
            });
        }
    },
    watch: {
        value() {
            if (this.chooseCountry !== this.value) {
                this.chooseCountry = JSON.parse(JSON.stringify(this.value));
            }
        }
    },
    methods: {
        changeCountry(e) {
            console.log(e);
            if (e) {
                const continent = [];
                const country = [];
                e.split(',').forEach(item => {
                    if (['欧洲', '亚洲', '大洋洲', '美洲', '非洲'].includes(item)) {
                        continent.push(item);
                    } else {
                        country.push(item);
                    }
                });
                this.$emit('input', country.join(','));
                this.$emit('update:continent', continent.join(','));
            } else {
                this.$emit('input', e);
                this.$emit('update:continent', '');
            }
        }
    },
    components: {
        ListFilter
    }
};
</script>
